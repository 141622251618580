.terms {
  width: 100%;
  max-width: 597px;
  margin: 1rem auto 2rem auto;
  font-weight: 500;
  color: #101520;
}

.terms-heading {
  margin-bottom: 34px;
  font-size: 32px;
  line-height: 42px;
}

.terms-subheading {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 20px;
}

.terms p {
  margin-bottom: 32px;
  font-size: 14px;
  color: #828B9F;
}

@media screen and (max-width: 576px) {
  .terms-heading {
    font-size: 28px;
  }
}