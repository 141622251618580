.game-card-lg {
	display: flex;
	width: 100%;
	background-color: #fff;
	border-radius: 1rem;
	font-weight: 500;
	color: #101520;
}

.game-card-lg-image {
	min-width: 9.25rem;
	width: 9.25rem;
	max-width: 9.25rem;
	height: 100%;
	border-radius: 1rem 0 0 1rem;
	object-fit: cover;
}

.game-card-lg-createdAt {
	font-size: 0.75rem;
	line-height: 1rem;
}

@media screen and (max-width: 991px) {
	.game-card-lg {
		flex-direction: column;
		height: auto;
	}

	.game-card-lg-image {
		width: 100%;
		max-width: none;
		height: 7.375rem;
		border-radius: 1rem 1rem 0 0;
	}

	.game-card-lg-createdAt {
		font-size: 0.625rem;
	}
}