.faq-accordion-trigger {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 20px;
	min-height: 20px;
	border-radius: 100%;
	background-color: #EAECF1;
	transition: 300ms transform ease-in-out;
}

[data-state="open"] .faq-accordion-trigger {
	transform: rotate(-45deg);
}

.faq-accordion-content {
	max-width: 412px;
	font-size: 14px;
	line-height: 20px;
	color: #828B9F;
	overflow: hidden;
}

.faq-accordion-content[data-state='open'] {
	animation: slideDown 300ms ease-in-out;
}

.faq-accordion-content[data-state='closed'] {
	animation: slideUp 300ms ease-in-out;
}

@keyframes slideDown {
	from {
		height: 0;
	}

	to {
		height: var(--radix-accordion-content-height);
	}
}

@keyframes slideUp {
	from {
		height: var(--radix-accordion-content-height);
	}

	to {
		height: 0;
	}
}