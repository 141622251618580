@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
	display: flex;
	min-width: 13.1875rem;
	padding: 0.8125rem 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 2.5625rem;
	background: #000 !important;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.5rem;
	color: #FFF;
	transition: opacity 0.2s ease-in-out;
}

.btn:disabled {
	opacity: 0.5;
}

html,
body {
	scrollbar-width: none;
}

.overflow-pseudo-hidden {
	overflow: auto;
	scrollbar-width: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
.overflow-pseudo-hidden::-webkit-scrollbar {
	display: none;
}

/* Chrome, Safari, Edge, Opera */
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

/* Firefox */
.input-number[type=number] {
	-moz-appearance: textfield;
}