.games-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0.875rem 0 2rem 0;
	font-weight: 500;
	color: #101520;
}

.games-header-heading {
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.75rem;
}

@media screen and (max-width: 576px) {
	.games-header {
		flex-direction: column;
		align-items: flex-start;
		gap: 1rem;
	}

	.games-header-heading {
		font-size: 1.25rem;
	}
}