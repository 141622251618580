.form-range-tooltip,
.double-range-tooltip {
  position: relative;
  top: -10px;
  display: flex;
  flex-grow: 1;
  min-height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  transform: translateY(-100%);
}
