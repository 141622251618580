.game-card-lg-muted {
	font-size: 0.75rem;
	font-style: normal;
	line-height: 1rem;
	color: #A0A4AC;
}

.game-card-lg-createdAt {
	font-size: 0.75rem;
	line-height: 1rem;
}

@media screen and (max-width: 768px) {

	.game-card-lg-muted,
	.game-card-lg-createdAt {
		font-size: 0.625rem;
	}
}