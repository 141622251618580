.dashboard-aside {
	display: flex;
	flex-direction: column;
	min-width: 19.625rem;
	width: 19.625rem;
	background: #000;
	color: #fff;
}

.dashboard-aside-nav-item {
	position: relative;
	display: flex;
	align-items: center;
	height: 4.75rem;
	margin-bottom: 0;
	padding: 1rem 0.94rem;
	border-radius: 1rem 0rem 0rem 1rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5rem;
}

.dashboard-aside-nav-item.active {
	background: #F3F3F3;
	color: #101520;
}

.dashboard-aside-nav-item-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.875rem;
	height: 2.875rem;
	margin-right: 1rem;
	border-radius: 0.5rem;
	background: rgba(255, 255, 255, 0.10);
}

.active .dashboard-aside-nav-item-icon {
	background: #000;
}

.dashboard-aside-nav-item-active {
	position: absolute;
	right: 0;
	top: -1.5rem;
	bottom: -1.5rem;
	width: 1.5rem;
	background: #F3F3F3;
	opacity: 0;
}

.active .dashboard-aside-nav-item-active {
	opacity: 1;
}

.dashboard-aside-nav-item-active::before,
.dashboard-aside-nav-item-active::after {
	content: '';
	top: 0;
	right: 0;
	width: 3rem;
	height: 3rem;
	position: absolute;
	background: #000;
	border-radius: 3.125rem;
	transform: translateY(-50%);
}

.dashboard-aside-nav-item-active::after {
	top: unset;
	bottom: 0;
	transform: translateY(50%);
}

.dashboard-aside-logout {
	display: inline-flex;
	align-items: center;
	gap: 1rem;
	margin: 0 0 0 2.5rem;
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.5rem;
	color: rgba(255, 255, 255, 0.60);
}

@media screen and (max-width: 768px) {
	.dashboard-aside {
		display: none;
	}

	.dashboard-aside-nav-item-icon {
		margin: 0;
		background: transparent;
	}
}
