.toast-status {
	position: relative;
}

.toast-status::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 4px;
	height: 100%;
}

.toast-status.toast-success::before {
	background: #A2CB9E;
}

.toast-status.toast-error::before {
	background: red;
}

.toast-shadow {
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}