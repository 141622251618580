.dialog-title {
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	color: #101520;
}

.dialog-text {
	color: #8C8C8C;
	text-align: center;
	font-family: ITC Avant Garde Gothic Pro;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

@media screen and (max-width: 576px) {
	.dialog-title {
		font-size: 20px;
		line-height: 24px;
	}
}