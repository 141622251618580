.faq {
	width: 100%;
	max-width: 597px;
	margin: 1rem auto 2rem auto;
	font-weight: 500;
	color: #101520;
}

.faq-heading {
	margin-bottom: 34px;
	font-size: 32px;
	line-height: 42px;
}

.faq-subheading {
	margin-bottom: 16px;
	font-size: 16px;
	line-height: 20px;
}

@media screen and (max-width: 576px) {
	.faq-heading {
		font-size: 28px;
	}
}