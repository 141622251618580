.dashboard-header-search {
	display: flex;
	padding: 0 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	flex: 1 0 0;
	border-radius: 2.5625rem;
	background: white;
}

.dashboard-header-search-input {
	width: 100%;
	padding: 0.8125rem 0;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1.5rem;
	outline: none;
}

.dashboard-header-search-input::placeholder {
	color: #B9BEC8;
}

.dashboard-header-action {
	display: flex;
	width: 13.1875rem;
	padding: 0.8125rem 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 2.5625rem;
	background: #000;
	color: #FFF;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.5rem;
}

.dashboard-header-notification,
.dashboard-header-wallet {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3.125rem;
	height: 3.125rem;
	border-radius: 3.125rem;
	background: white !important;
	outline: none;
}

.dashboard-header-notification img {
	width: 100%;
	height: 100%;
	border-radius: 3.125rem;
	object-fit: cover;
}

.dashboard-header-wallet img {
	width: 2rem;
	height: 2rem;
	object-fit: cover;
}

.dashboard-header-wallet svg {
	width: 1.5625rem;
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 576px) {
	.dashboard-header-search {
		display: none;
	}

	.dashboard-header-notification,
	.dashboard-header-wallet {
		width: 2.5rem;
		height: 2.5rem;
	}

	.dashboard-header-wallet img,
	.dashboard-header-notification svg {
		width: 1.625rem;
		height: 1.625rem;
	}

	.dashboard-header-wallet svg {
		width: 1.25rem;
	}
}