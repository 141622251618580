.btn-game {
	display: flex;
	width: 200px;
	padding: 13px 24px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 41px;
	background: #000;
	font-size: 12px;
	font-weight: 500;
	line-height: 24px;
	color: #fff;
}

.btn-game.in-progress {
	background: #F4F4F4;
	color: #101520;
}

@media screen and (max-width: 992px) {
	.btn-game {
		width: 100%;
	}
}