.tooltip {
	display: inline-flex;
	padding: 16px;
	max-width: 238px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	border-radius: 12px;
	border: 0.5px solid #F0F0F0;
	background: #FFF;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	color: #A0A4AC;
	z-index: 100;
}